import React, {useEffect, useMemo, useState} from 'react';
import {
  createAutoSuggest, createAutoSuggestCheckLink,
  getAccountInfo,
} from '../../../services/api';
import DataTable from '../../common/DataTable';
import {Form, Popconfirm, Skeleton, Space, message, Avatar, Button, Flex, Input, Tag, Tooltip} from 'antd';
import {
  CheckOutlined, DownloadOutlined, LoadingOutlined
} from '@ant-design/icons';
import ModalCheckLinkFree from './ModalCheckLinkFree';
import TextArea from "antd/es/input/TextArea";
import ImportExcelCheckLinkFree from "./UploadExcel";
import {formatIndex, formatIsFollow, formatIsIndex} from "../../common/function";
import {AiOutlineArrowRight, AiOutlineArrowUp} from "react-icons/ai";
import {INDEX_LINK} from "../../common/Enum";
import {useNavigate} from "react-router-dom";
import ModalCheckLinkNow from "./ModalCheckLinkNow";

export default function CheckLinkFree() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    limit: 10,
    totalPage: 1,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalCheckLink, setIsOpenModalCheckLink] = useState(false);
  const [userId, setUserId] = useState();
  const [form] = Form.useForm();
  const [indexedCount, setIndexedCount] = useState(0);
  const [totalLinks, setTotalLinks] = useState(0);

  useEffect(() => {
    getRoleId();
  }, []);

  // kiểm tra user phải leader ko
  const getRoleId = async () => {
    let res = await getAccountInfo();
    setUserId(res?.data?.roleId?.name);
  };


  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text, data, index) => index + 1,
    },
    {
      title: 'Link',
      dataIndex: 'url',
    },
    {
      title: 'Indexed',
      dataIndex: 'indexed',
      // render: (text, _) => {
      //   const {isIndex} = _;
      //   return (
      //       <>
      //         <p style={{color: formatIsIndex(isIndex).color}}>
      //           {formatIsIndex(isIndex).text}
      //         </p>
      //       </>
      //   )
      // }
      render: (text) => {
        return (
            <>
              {text === INDEX_LINK.PROCESSING_CHECK_INDEX ? (
                  <Button
                      type='reset'
                      size={'small'}
                      className='processing-index flex items-center bg-slate-600 text-white'
                      style={{margin: 0}}
                      icon={<LoadingOutlined spin={loading}/>}
                  >
                    Đang xử lý
                  </Button>
              ) : (
                  <Tag color={formatIndex(text).color}>
                    {formatIndex(text).text}
                  </Tag>
              )}
            </>
        )
      }
    },
  ];


  const handleRefresh = () => {
    // setSearch("");
    setLinks([]);
    form.resetFields();
  };

  const paginate = (currentPage, limit) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: currentPage,
      limit: limit,
    }));
  };

  const loadLink = () => {
    form.validateFields().then(values => {
      const { linkUrl } = values;
      const linksArray = linkUrl.split('\n').map(link => link.trim());
      const uniqueLinks = [...new Set(linksArray)].map(link => ({
        url: link,
        indexed: null
      }));
      setLinks(uniqueLinks);
    }).catch(errorInfo => {
      console.log('Validation Failed:', errorInfo);
    });
  };

  const handleCheckLinkIndex = () => {
    if (!links.length) {
      message.error('Vui lòng nhập link trước');
      return;
    }

    const isProcessing = links.some(link => link.indexed === INDEX_LINK.PROCESSING_CHECK_INDEX);
    if (isProcessing) {
      message.error('Link đang được thực hiện');
      return;
    }

    const allChecked = links.every(link => link.indexed !== INDEX_LINK.PROCESSING_CHECK_INDEX && link.indexed !== null);
    if (allChecked) {
      message.success('Link đã được check thành công');
      return;
    }

    const linkData = links.map((item) => {
      return {
        link: `site:${item.url}`,
      }
    });
    window.postMessage({
      type: "OKCHECK_WRITE_INDEX",
      message: linkData,
    });

    const updatedLinks = links.map(link => ({
      ...link,
      indexed: INDEX_LINK.PROCESSING_CHECK_INDEX
    }));
    setLinks(updatedLinks);

    setTimeout(() => {
      window.postMessage({
        type: "OKCHECK_CHECK_INDEX",
      });
    }, 1000)
  }

  const handleIndexNow = () => {
    if (!links.length) {
      message.error('Vui lòng nhập link trước');
      return;
    }

    const isProcessing = links.some(link => link.indexed === INDEX_LINK.PROCESSING_CHECK_INDEX);
    if (isProcessing) {
      message.error('Link đang được thực hiện');
      return;
    }
    const linksFiltered = links.filter(link => link.indexed !== null);
    if(!linksFiltered.length) {
      message.error('Vui lòng check link trước');
      return;
    }
    setIsOpen(true);
  }

  const handleCheckLinkNow = () => {
    if (!links.length) {
      message.error('Vui lòng nhập link trước');
      return;
    }

    const isProcessing = links.some(link => link.indexed === INDEX_LINK.PROCESSING_CHECK_INDEX);
    if (isProcessing) {
      message.error('Link đang được thực hiện');
      return;
    }
    const linksFiltered = links.filter(link => link.indexed !== null);
    if(!linksFiltered.length) {
      message.error('Vui lòng check link trước');
      return;
    }
    setIsOpenModalCheckLink(true);
  }

  const submitModal = async (values) => {
    console.log(values);
    const payload = {
        linkUrl: links,
        websiteId: values.websiteId,
        configIndexId: values.configIndexId,
    }
    const res = await createAutoSuggest(payload);
    if(res?.status) {
        message.success('Tạo đề xuất tự động INDEX thành công');
        setIsOpen(false);
      navigate(`/detail/${res.data._id}`);
    } else {
      message.error(res.message);
    }
  }

  const submitModalCheckLink = async (values) => {
    console.log(values);
    const payload = {
      linkUrl: links,
      websiteId: values.websiteId,
      configIndexId: values.configIndexId,
      keywords: values.keywords || [],
    }
    const res = await createAutoSuggestCheckLink(payload);
    if(res?.status) {
      message.success('Tạo đề xuất tự động check link thành công');
      setIsOpenModalCheckLink(false);
      navigate(`/detail/${res.data._id}`);
    } else {
      message.error(res.message);
    }
  }

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const isInstalled = document.getElementById('CheckLinkIndex') !== null;
      console.log(isInstalled);
      setIsExtensionInstalled(isInstalled);
    }, 1000); // 1 second delay

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "UPDATE_RESULT") {
        const data = event.data.data;
        // Handle the data received from the background script
        console.log("Received data from background script:", data);

        // Extract link and index from the received data
        const { link, index } = data;
        // Remove the 'site:' prefix from the link
        const cleanLink = link.replace(/^site:/, '');

        // Update the links array
        setLinks((prevLinks) =>
            prevLinks.map((item) =>
                item.url === cleanLink ? { ...item, indexed: index === 'Indexed' ? 'index': 'noindex' } : item
            )
        );
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    console.log(links)
    const count = links.filter(link => link.indexed === 'index').length;
    setIndexedCount(count);
    setTotalLinks(links.length);
  }, [links]);

  return (
      <>
        <p className='text-2xl font-semibold py-4'>Check Link Index Free</p>
        <div className='py-4 w-full'>
          <p className='text-xl font-bold text-blue-500'>*Hướng dẫn sử dụng tính năng CHECK LINK INDEX FREE:</p>
          <ul className="w-full mt-4">
            <li className="flex items-center gap-1">
              ** Xem hướng dẫn cách cài đặt Extension check link index
              <a
                  href='https://drive.google.com/file/d/1Siz2ik0c0k0CzRXXGU67IQhMD15L5E20/view'
                  className='text-blue-500 underline'
                  target="_blank"
              >tại đây
              </a>
            </li>
            <li className="flex items-center gap-1">
              1. Cài đặt Extension Check Link Index tại đây:
              <a
                  href='/extension/OkCheckIndex.zip'
                  download
                  className='text-blue-500 underline'
              >
                <DownloadOutlined/> Download
              </a>
            </li>
            <li className="flex items-center gap-1">
              2. Refresh lại page:
              <span
                  className='text-blue-500 underline'
              >check-link-free</span>
            </li>
            <li className="flex items-center gap-1">
              3. Bây giờ, bạn có thể sử dụng tính năng check link INDEX free.
            </li>
          </ul>
          <div className="mt-4">
            <p className="text-red-600 text-xl font-semibold">* Lưu ý:</p>
            <p>
              - Khi sử dụng CHECK INDEX nếu CHECK với lượng LINK lớn
            </p>
            <p>
              - Thì khi Google hiển thị tab bắt CHECK CAPTCHA thì bạn cần check captcha
            </p>
            <p>
              - Sau khi CHECK CAPTCHA thành công bạn vui lòng tắt TAB của Gogle để PM tiếp tục CHECK.
            </p>
          </div>
        </div>
        {
            !isExtensionInstalled && (
                <p className="text-xl font-bold text-red-600">**Trình duyệt của bạn chưa được cài đặt Extension</p>
            )
        }
        <Flex gap={'small'} className='mt-6 flex items-center'>
          {/*<Space.Compact style={{width: 300}}>*/}
          {/*  <Input*/}
          {/*      addonBefore={<SearchOutlined/>}*/}
          {/*      placeholder="Tìm kiếm nhóm Tele..."*/}
          {/*      onChange={(e) => setSearch(e.target.value)}*/}
          {/*      allowClear*/}
          {/*      value={search}*/}
          {/*  />*/}
          {/*</Space.Compact>*/}
          <ImportExcelCheckLinkFree setLinks={setLinks}/>

          {/*<Button type='primary' className='add-btn !m-0' onClick={() => openModal()}>*/}
          {/*  Thêm Nhóm Telegram*/}
          {/*</Button>*/}
          <Button
              onClick={handleRefresh}
              className='add-reset !m-0 flex items-center bg-slate-600 text-white'
          >
            Clear
          </Button>
        </Flex>
        <Form
            form={form}
            // onFinish={loadLink}
            autoComplete='on'
            layout='vertical'
        >
          <Form.Item
              name='linkUrl'
              label={'Link URL, không nhập trùng link'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập URL',
                },
                // {
                //   validator: (_, value) => {
                //     if (!value) {
                //       return Promise.resolve();
                //     }
                //     const urls = value.split('\n').map(link => link.trim());
                //     const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*$/;
                //     for (let url of urls) {
                //       if (!urlPattern.test(url)) {
                //         return Promise.reject(new Error('Vui lòng nhập URL hợp lệ'));
                //       }
                //     }
                //     return Promise.resolve();
                //   },
                // },
              ]}
          >
            <TextArea
                rows={8}
                placeholder='vd: web.com
                www.web.net'
            />
          </Form.Item>

          <div className='text-left mb-10 mt-4'>
            <Space>
              <Button size='large' type='primary' danger icon={<CheckOutlined/>} onClick={loadLink}>
                1.Load Link
              </Button>
              <Tooltip
                  title={`${!isExtensionInstalled ? 'Vui lòng cài đặt Extension Check Index để sử dụng chức năng' : ''}`}>
                <Button disabled={!isExtensionInstalled} size='large' type='primary' icon={<AiOutlineArrowRight/>}
                        onClick={handleCheckLinkIndex} className='disabled:opacity-60 btn-modal'>
                  2.Check Indexing Link
                </Button>
              </Tooltip>
              <Button size='large' onClick={handleIndexNow} type='primary' icon={<AiOutlineArrowUp/>}
                      className='btn-modal'>
                3.Index Now
              </Button>
              <p>Hoặc</p>
              <Button size='large' onClick={handleCheckLinkNow} type='primary' icon={<AiOutlineArrowUp/>}
                      className='btn-modal'>
                4.Check Link Now
              </Button>
            </Space>
          </div>
        </Form>
        <div className="flex items-center gap-2 my-4">
          <p className="text-2xl font-bold">Tỷ lệ Index:</p>
          <p className="text-2xl text-blue-500 font-bold">{indexedCount} / {totalLinks}</p>
        </div>
        <div className="flex items-center gap-2 my-4">
          <p className="text-2xl font-bold">Tỷ lệ:</p>
          <p className="text-2xl text-blue-500 font-bold">
            {totalLinks > 0 ? Math.ceil((indexedCount / totalLinks) * 100) : 0}%
          </p>
        </div>
        <DataTable
            columns={columns}
            listData={links}
            pageSize={pagination.limit}
            indexPage={pagination.currentPage}
            totalPage={pagination.totalPage}
            scroll={{x: 1500}}
            onPageChange={paginate}
            isPagination={false}
        />

        {/* modal tele */}
        <ModalCheckLinkFree
            title={'Tạo đề xuất tự động Index Link'}
            show={isOpen}
            handleCloseModal={() => setIsOpen(false)}
            form={form}
            submitModal={submitModal}
            userId={userId}
        />
        <ModalCheckLinkNow
            title={'Tạo đề xuất tự động Check Link Nhanh'}
            show={isOpenModalCheckLink}
            handleCloseModal={() => setIsOpenModalCheckLink(false)}
            form={form}
            submitModal={submitModalCheckLink}
            userId={userId}
        />
      </>
  );
}
